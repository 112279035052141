<template>
  <div class="class_error">
    <router-link to="/home">返回首页</router-link>
    <div class="img_box">
      <img src="../assets/images/404.png">
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      error: ''
    }
  },
  methods: {

  },
  created () {

  }
}
</script>
<style lang="less" scoped>
.img_box {
  display: flex;
  justify-content: center;
}
</style>
