<template>
  <div class="login_container">
    <div class="login_box">
      <!-- 头像区域 -->
      <!-- <div class="logo_box">
        <img src="../assets/images/logo.png" alt="">
      </div> -->
      <div class="data_view">
        <img style="max-width:180px;margin:0 auto" src="../assets/images/feifaneyes.png" alt="">
        <p style="margin:0;padding:0">非凡之眼</p>
      </div>
      <!-- 登录表单 -->
      <div class="login_from">
        <el-form ref="LoginFormRef" :rules="loginFromRules" :model="loginData">
          <!-- 用户名 -->
          <el-form-item prop="username">
            <el-input prefix-icon="el-icon-user" placeholder="请输入用户名" v-model="loginData.username"></el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item prop="password">
            <el-input prefix-icon="el-icon-unlock" placeholder="请输入密码" type="password"
                      v-model="loginData.password"></el-input>
          </el-form-item>
          <!-- 按钮 -->
          <el-form-item class="login_button">
            <el-button style="width:405px;" type="primary" @click="login">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { setRoutes } from "@/router"

import sessionTool from '@/utils/sessionTool'
export default {
  name: "Login",

  components: {},
  data () {
    return {
      // 表单验证规则
      loginFromRules: {
        // 验证用户名
        username: [{ required: true, message: '请输入登录账户', trigger: 'blur' }, { min: 1, max: 15, message: '账户应在1和15之间', trigger: 'blur' }],
        // 验证密码
        password: [{ required: true, message: '请输入登录密码', trigger: 'blur' }, { min: 1, max: 15, message: '密码应在1和15之间', trigger: 'blur' }]
      },
      // 表单数据模型
      loginData: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    // 登录
    login () {
      // 验证
      this.$refs.LoginFormRef.validate((valid) => {
        if (!valid) { return false }
        this.$user.login(this.loginData).then(res => {
          console.log(res)
          if (res.code === 1) {
            //let loginUser = res.data;
            // 保存用户数据到会话存储
            //sessionTool.saveLoginUser(loginUser)
            // sessionStorage.setItem("loginUser", JSON.stringify(loginUser))
            //转跳到home页
            //this.$message.success('登录成功');
            //this.$router.push({ path: '/Layout' });


            localStorage.setItem("user", JSON.stringify(res.data))//存储用户信息到浏览器
            localStorage.setItem("menus", JSON.stringify(res.data.menus))//存储用户权限菜单信息到浏览器
            //动态设置当前用户的路由
            setRoutes()
            this.$router.push("/home")
            this.$message.success("登录成功")
          } else {
            console.log(res.msg)
            this.$message.error(res.msg)
          }
        }).catch(err => {
          this.$message.error("登录失败")
          console.log(err)
        })


      })

    },
    // 重置表单
    // reset() {
    //   this.$refs.LoginFormRef.resetFields();
    // }
  },
  created () {
    // 修改默认的用户名和密码
    this.loginData.name = ''
    this.loginData.password = ''
  }
}
</script>

<style lang="less" scoped>
.login_container {
  height: 100%;
  background-image: url(../assets/images/u40.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.login_box {
  width: 450px;
  height: 500px;
  position: absolute;
  left: 65%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #f5f3f3;
  box-shadow: 0px 25px 25px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.logo_box {
  width: 100px;
  height: 100px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #eee;
  }
}

.login_from {
  height: 60%;
  position: absolute;
  bottom: -36px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.login_button {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.data_view {
  position: relative;
  text-align: center;
  /* 调整垂直间距 */
  font-size: 26px;
  color: rgb(89, 129, 129);
  font-weight: 600;
  p {
    position: absolute;
    left: 39%;
    top: 140px;
  }
}
</style>
