import Vue from 'vue'
import VueRouter from 'vue-router'
import Error from '../views/error.vue'
import Login from '../views/Login.vue'
import store from "@/store"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '*',
    name: 'Error',
    component: Error
  },
  // {
  //   path: '/Layout',
  //   component: () => import('../components/Layout'),
  //   children: [
  //     {
  //       path: '/',
  //       name: 'welcomePage',
  //       component: () => import('../components/welcome.vue')
  //     },
  //     {
  //       path: 'SpyData',
  //       name: 'SpyDataPage',
  //       component: () => import('../views/SpyData.vue')
  //     },
  //     {
  //       path: 'SpyTimeData',
  //       name: 'SpyTimeDataPage',
  //       component: () => import('../views/SpyTimeData.vue')
  //     },
  //     {
  //       path: 'WebSort',
  //       name: 'WebSort',
  //       component: () => import('../views/WebSort.vue')
  //     },
  //     {
  //       path: 'allDomain',
  //       name: 'allDomain',
  //       component: () => import('../views/AllDomain.vue')
  //     },
  //     {
  //       path: 'domainHome',
  //       name: 'domainHome',
  //       component: () => import('../views/DomainHome.vue')
  //     },
  //     {
  //       path: 'webNumber',
  //       name: 'webNumber',
  //       component: () => import('../views/WebNumber.vue')
  //     },
  //     {
  //       path: 'domainInfo',
  //       name: 'domainInfo',
  //       component: () => import('../views/DomainInfo.vue')
  //     },
  //     {
  //       path: 'nameServersInfo',
  //       name: 'nameServersInfo',
  //       component: () => import('../views/NameServersInfo.vue')
  //     },
  //     {
  //       path: 'cfddetails',
  //       name: 'cfddetails',
  //       component: () => import('../views/Cfddetails.vue')
  //     },
  //     {
  //       path: 'addTadsaccname',
  //       name: 'addTadsaccname',
  //       component: () => import('../views/addTadsaccname.vue')
  //     },
  //     {
  //       path: 'adsaccnameDetail',
  //       name: 'adsaccnameDetail',
  //       component: () => import('../views/AdsaccnameDetail.vue')
  //     },
  //     {
  //       path: 'bingDetail',
  //       name: 'bingDetail',
  //       component: () => import('../views/BingDetail.vue')
  //     },
  //     {
  //       path: 'clicklogtb',
  //       name: 'clicklogtb',
  //       component: () => import('../views/clicklogtb.vue')
  //     },
  //     {
  //       path: 'upDomainDate',
  //       name: 'upDomainDate',
  //       component: () => import('../views/upDomainDate.vue')
  //     },
  //     {
  //       path: 'offerDetail',
  //       name: 'offerDetail',
  //       component: () => import('../views/offerDetail.vue')
  //     },
  //     {
  //       path: 'domainDateDetails',
  //       name: 'domainDateDetails',
  //       component: () => import('../views/DomainDateDetails.vue')
  //     },
  //     {
  //       path: 'domainDetails',
  //       name: 'domainDetails',
  //       component: () => import('../views/DomainDetails.vue')
  //     },
  //     {
  //       path: 'advertiserDataDisplay',
  //       name: 'advertiserDataDisplay',
  //       component: () => import('../views/AdvertiserDataDisplay.vue')
  //     },
  //     {
  //       path: 'advertiserIdData',
  //       name: 'advertiserIdData',
  //       component: () => import('../views/AdvertiserIdData.vue')
  //     },
  //     {
  //       path: 'advertisement',
  //       name: 'advertisement',
  //       component: () => import('../views/Advertisement.vue')
  //     },
  //     {
  //       path: 'addAdvertiserName',
  //       name: 'addAdvertiserName',
  //       component: () => import('../views/addAdvertiserName.vue')
  //     },
  //     {
  //       path: 'dropDomain',
  //       name: 'dropDomain',
  //       component: () => import('../views/dropDomain.vue')
  //     },
  //     {
  //       path: 'advertisementInfo',
  //       name: 'advertisementInfo',
  //       component: () => import('../views/advertisementInfo.vue')
  //     },
  //     {
  //       path: 'spyConfigPage',
  //       name: 'spyConfigPage',
  //       component: () => import('../views/spyConfigPage.vue')
  //     },
  //     {
  //       path: 'spyKeyWordConfigPage',
  //       name: 'spyKeyWordConfigPage',
  //       component: () => import('../views/spyKeyWordConfigPage.vue')
  //     },
  //     {
  //       path: 'uploadFeedTable',
  //       name: 'uploadFeedTable',
  //       component: () => import('../views/uploadFeedTable.vue')
  //     },
  //   ]
  // },
]

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// 提供一个重置路由的方法
export const resetRouter = () => {
  router.matcher = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
  })
}

//刷新页面会重置路由
export const setRoutes = () => {
  const storeMenus = localStorage.getItem("menus")
  if (storeMenus) {
    //拼装动态路由
    const manageRoute = {
      path: '/', name: 'Layout', component: () => import('../views/Layout.vue'), redirect: '/home', children: [
        // { path: 'person', name: '个人信息', component: () => import('../views/Person.vue') },
        // { path: 'password', name: '修改密码', component: () => import('../views/Password.vue') }
      ]
    }

    const menus = JSON.parse(storeMenus)

    menus.forEach(item => {
      if (item.path) {//当且仅当path不为空的时候才去设置路由
        let itemMenu = { path: item.path.replace("/", ""), name: item.name, component: () => import('../views/' + item.pagePath + '.vue') }
        manageRoute.children.push(itemMenu)
      } else if (item.children.length) {
        item.children.forEach(item => {
          if (item.path) {
            let itemMenu = { path: item.path.replace("/", ""), name: item.name, component: () => import('../views/' + item.pagePath + '.vue') }
            manageRoute.children.push(itemMenu)
          }
        })
      }
    })
    //获取当前的路由对象名称数组
    const currentRouteNames = router.getRoutes().map(v => v.name)
    if (!currentRouteNames.includes('Layout')) {
      //动态加载到想在的路由对象
      router.addRoute(manageRoute)
    }
  }
}
setRoutes()

router.beforeEach((to, from, next) => {
  localStorage.setItem("currentPathName", to.name)  // 设置当前的路由名称
  store.commit("setPath")


  // 未找到路由的情况
  if (!to.matched.length) {
    const storeMenus = localStorage.getItem("menus")

    // console.log("未找到路由的情况 storeMenus == " + storeMenus)

    if (storeMenus) {
      next("/error")
    } else {
      // 跳回登录页面
      next("/login")
    }
  }


  // 其他的情况都放行
  next()

})

export default router
