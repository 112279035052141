<template>
  <div id="app">

    <!-- 路由占位符 -->
    <router-view></router-view>

  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style lang="less">
body {
  margin: 0;
  padding: 0;
}
#app {
  height: 100vh;
}
</style>

<!-- 
//去除侧边滚动条
<style lang="less">
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style> -->
