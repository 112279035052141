import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './plugins/element.js'
import './assets/css/global.css'
import axios from 'axios'
// import BaiduMap from 'vue-baidu-map'
import { Message } from 'element-ui'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import store from './store'

Vue.use(ElementUI)


// 配置请求根路径
axios.defaults.baseURL = '/api'



// 添加请求拦截器
// axios.interceptors.request.use(config => {
//   // 自定义属性Authorization并将token添加到请求头
//   const token = window.sessionStorage.getItem('token');
//   config.headers.token = token
//   return config;
// })



Vue.config.productionTip = false


// 注册axios全局对象

import apis from "./apis"
for (let key in apis) {
  Vue.prototype['$' + key] = apis[key]
}

// Vue.prototype.$http = axios


// 响应拦截器，处理响应数据
// axios.interceptors.response.use(
//   response => {
//     console.log(response.headers);
//     if (response.data.msg === 'NOTLOGIN') {
//       Message.error('登录已失效,请重新登录');
//       if (router.path !== '/login') {
//         router.push('/login').catch((err) => {
//           console.log(err);
//         });
//       }
//     }
//     return response
//   },
//   // error => {
//   //   if (error.response.status === 500) {
//   //     router.push('/error');
//   //   } else {
//   //     console.error('请求错误：', error);
//   //   }
//   //   return false;
//   // }
// )

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app')