import request from "@/utils/request"

export default {
        // 登录spy
        // login: (params) => request.post('/user/login', params)
        login: (params) => request.post('/sysUser/login', params),
        getUser: (params) => request.get('/sysUser/page', params),
        role: () => request.get('/role'),
        saveUser: (params) => request.post('/sysUser', params),
        deleteUser: (id) => request.get("/sysUser/del/" + id),
        deleteUserBatch: (ids) => request.post("/sysUser/del/batch/", ids),
        rolePage: (params) => request.get("/role/page",params),
        getMenu: (params) => request.get("/menu",params),
        getMyMenu: (roleId) => request.get("/role/roleMenu/"+roleId),
        getMenuId: () => request.get("/menu/ids"),
        saveRole: (params) => request.post("/role",params),
        saveRoleMenu: (roleId,params) => request.post("/role/roleMenu/"+ roleId,params),
        deleteRole: (id) => request.get("/role/del/"+ id),
        deleteRoleBatch: (params) => request.post("/role/del/batch",params),
        getIcons: () => request.get("/menu/icons"),
        saveMenu: (params) => request.post("/menu",params),
        deleteMenu: (id) => request.get("/menu/del/"+id),
        deleteMenuBatch: (ids) => request.post("/menu/del//batch",ids),
}